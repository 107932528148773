import { NgModule } from '@angular/core';

import { COWTUXWidgetComponent } from './widget.component';
import { COWTUXWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        COWTUXWidgetComponent,
        COWTUXWidgetToggleDirective
    ],
    exports     : [
        COWTUXWidgetComponent,
        COWTUXWidgetToggleDirective
    ],
})
export class COWTUXWidgetModule
{
}
