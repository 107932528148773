import { NgModule } from '@angular/core';

import { COWTUXHighlightComponent } from '@cowtux/components/highlight/highlight.component';

@NgModule({
    declarations: [
        COWTUXHighlightComponent
    ],
    exports: [
        COWTUXHighlightComponent
    ],
})
export class COWTUXHighlightModule {
}
