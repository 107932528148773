import { NgModule } from '@angular/core';

import { COWTUXIfOnDomDirective } from '@cowtux/directives/cowtux-if-on-dom/cowtux-if-on-dom.directive';
import { COWTUXInnerScrollDirective } from '@cowtux/directives/cowtux-inner-scroll/cowtux-inner-scroll.directive';
import { COWTUXPerfectScrollbarDirective } from '@cowtux/directives/cowtux-perfect-scrollbar/cowtux-perfect-scrollbar.directive';
import { COWTUXMatSidenavHelperDirective, OWTUXMatSidenavTogglerDirective } from '@cowtux/directives/cowtux-mat-sidenav/cowtux-mat-sidenav.directive';

@NgModule({
    declarations: [
        COWTUXIfOnDomDirective,
        COWTUXInnerScrollDirective,
        COWTUXMatSidenavHelperDirective,
        OWTUXMatSidenavTogglerDirective,
        COWTUXPerfectScrollbarDirective
    ],
    imports: [],
    exports: [
        COWTUXIfOnDomDirective,
        COWTUXInnerScrollDirective,
        COWTUXMatSidenavHelperDirective,
        OWTUXMatSidenavTogglerDirective,
        COWTUXPerfectScrollbarDirective
    ]
})
export class COWTUXDirectivesModule {
}
