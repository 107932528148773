export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'MEMBERS': 'Members',
            'DRIVERS': 'Drivers',
            'VEHICLES': 'Vehicles',
            'PAYERS': 'Payers',
            'DISPATCHMANAGEMENT': 'Dispatch Management',
            'DISPATCHER': 'Dispatcher',
            'MANUALENTRY': 'Manual Entry',
            'FINANCEMANAGEMENT': 'Billing',
            'REPORTMANAGEMENT': 'Reports',
            'DAILYTRIPSLOG': 'Daily Trips Log',
            'DRIVERTRIPSLOG': 'Driver Trips Log',
            'CONFIGSETTINGS': 'Config Settings',
            'ADMIN': 'Admin',
            'CONFIG': 'Config',
            'COMPANIES': 'Companies',
            'USERMANAGEMENT': 'User Management',
            'MASTERMANAGEMENT': 'Master Management',
            'COUNTRIES': 'Countries',
            'STATES': 'States',
            'ZONES': 'Zones',
            'USERROLES': 'User Roles',
            'LEVELSOFSERVICE': 'Levels Of Service',
            'VEHICLEGROUPS': 'Vehicle Groups',
            'CAPACITYTYPES': 'Capacity Types',
            'PROCESSBILLING': 'Process Billing',
            'INSURANCETYPES': 'Insurance Types',
            'PATIENTTYPES': 'Patient Types',
            'COURTESYCALLING': 'Courtesy Calling',
            'EMULATOR': 'Emulator',
            'VEHICLECONDITIONS': 'Vehicle Conditions',
            'DRIVERREPORTS': 'Driver Reports',
            'FILEIMPORT': 'File Import',
            'SCHEDULER': 'Scheduler',
            'AUDITREPORT': 'Audit Report',
            'VEHICLESONMAP': 'Vehicles On Map',
            'PAYROLL': 'Payroll',
            'DEFAULPAYROLLTCONFIG': 'Default Payroll Config',
            'EMPLOYEESPAYROLLSETTINGS': 'Employees Payroll Settings',
            'PAYROLLAMOUNTS': 'Payroll Amounts',
            'GEOCODINGCONFIG': 'Geo Coding Config',
            'GEOCODINGMODULES': 'Geo Coding Modules',
            'GEOCODINGFUNCTIONALITIES': 'Geo Coding Functionalities',
            'EMPPAYROLLAMOUNTS':'Employees Payroll amount',
            'ALLTRIPSREPORTS':'All Trips ',
            'UNASSIGNEDTRIPSREPORTS':'Unassigned Trips ',
            'DISPATCHERREPORTMANAGEMENT':'Dispatcher Reports',
            'DRIVNGTIMECONFIG':'Driving Times Config',
            'UNABLETOCANCEL':'Unable To Cancel'
        }
    }
};
