import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RestClientBaseService } from './rest-client-base.service';
import { Driver } from 'app/main/apps/shared/models/Driver';
import { LevelOfService, Zone, VehicleGroup } from 'app/main/apps/shared/models/MasterDataModel';
import { Payer } from 'app/main/apps/shared/models/Payer';
import { CustomImportSettings } from 'app/main/apps/shared/models/CustomImportSettings';
import { empty, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MasterDataService extends RestClientBaseService {
    constructor(private http: HttpClient) {
        super();
    }

    /** Companies */
    AddCompany(Data) {
        return this.http.post(environment.baseUrl + 'companies', Data, this.httpOptions);
    }
    GetAllCompanies(): any {
        return this.http.get(environment.baseUrl + "companies", this.httpOptions);
    }
    CompanyById(Id): any {
        return this.http.get(environment.baseUrl + "companies/" + Id, this.httpOptions);
    }
    CompanyByUserId(Id): any {
        return this.http.get(environment.baseUrl + "companies/byuserid/" + Id, this.httpOptions);
    }
    Getusercompanysselectionoptions(): any {
        return this.http.get(environment.baseUrl + "companies/getusercompaniesselectionoptions", this.httpOptions);
    }
    AddCompanyToUser(Data): any {
        return this.http.post(environment.baseUrl + 'companies/user', Data, this.httpOptions);
    }
    RemoveUserFromCompany(userId, companyID) {
        return this.http.delete(environment.baseUrl + 'companies/user?companyId=' + companyID + '&' + 'userId=' + userId, this.httpOptions);
    }

    /** End Organization */

    /** Company */
    AddBranch(Data) {
        return this.http.post(environment.baseUrl + 'branches', Data, this.httpOptions);
    }

    Branches(): any {
        return this.http.get(environment.baseUrl + "branches", this.httpOptions);
    }
    BranchesBycompanyId(Id): any {
        return this.http.get(environment.baseUrl + "branches/bycompanyid/" + Id, this.httpOptions);
    }
    GetUserBranchesSelectionOptions(Id): any {
        return this.http.get(environment.baseUrl + "branches/getuserbranchesselectionoptions?compId=" + Id, this.httpOptions);
    }
    BranchesById(Id): any {
        return this.http.get(environment.baseUrl + "branches/" + Id, this.httpOptions);
    }

    AddUserToBranch(Data) {
        return this.http.post(environment.baseUrl + 'branches/user', Data, this.httpOptions);
    }
    RemoveUserFromBranch(userId, branchID) {
        return this.http.delete(environment.baseUrl + 'branches/user?branchID=' + branchID + '&' + 'userId=' + userId, this.httpOptions);
    }
    BranchesByUserId(Id): any {
        return this.http.get(environment.baseUrl + "branches/byuserid/" + Id, this.httpOptions);
    }

    /** End Company */

    /** Members */
    AddMember(Data) {
        return this.http.post(environment.baseUrl + 'member', Data, this.httpOptions);
    }
    Members(Id): any {
        return this.http.get(environment.baseUrl + "member?branchId=" + Id, this.httpOptions);
    }
    MemberById(Id): any {
        return this.http.get(environment.baseUrl + "member/" + Id, this.httpOptions);
    }
    MembersBySearch(searchTerm, branchID): any {
        return this.http.get(environment.baseUrl + "member/search?q=" + searchTerm + '&branchId=' + branchID, this.httpOptions);
    }
    /** End Members */
    /** Country */
    AddCountry(Data) {
        return this.http.post(environment.baseUrl + 'master/country', Data, this.httpOptions);
    }
    GetAllCountry(): any {
        return this.http.get(environment.baseUrl + "master/country", this.httpOptions);
    }
    CountryById(Id): any {
        return this.http.get(environment.baseUrl + "master/country/" + Id, this.httpOptions);
    }
    /** End Country */

    /** State */
    AddState(Data) {
        return this.http.post(environment.baseUrl + 'master/state', Data, this.httpOptions);
    }
    GetAllState(): any {
        return this.http.get(environment.baseUrl + "master/state", this.httpOptions);
    }
    StateById(Id): any {
        return this.http.get(environment.baseUrl + "master/state/" + Id, this.httpOptions);
    }
    /** Payer */
    /** Zone */
    AddZone(Data) {
        return this.http.post(environment.baseUrl + 'master/zone', Data, this.httpOptions);
    }
    ZonesByBranchId(Id): any {
        return this.http.get(environment.baseUrl + "master/zone?branchId=" + Id, this.httpOptions);
    }
    ZoneById(Id): any {
        return this.http.get(environment.baseUrl + "master/zone/" + Id, this.httpOptions);
    }
    UpdateZoneById(id, request) {
        return this.http.put<Zone>(environment.baseUrl + "master/zone/" + id, request, this.httpOptions);
    }
    DeleteZoneById(Id): any {
        return this.http.delete(environment.baseUrl + "master/zone/" + Id, this.httpOptions);
    }
    
    UpdateTripByDriver(trip) {
        return this.http.post(environment.baseUrl + 'trip/UpdateTripByDriver',trip , this.httpOptions);

    }
    UpdateTemplateTrip(templateTrips){
        return this.http.put<any>(environment.baseUrl + 'trip/UpdateTemplateTrip',templateTrips, this.httpOptions);
    }
    /** Zone */
    AddPayer(Data) {
        return this.http.post(environment.baseUrl + 'payer', Data, this.httpOptions);
    }
    GetPayerForPermileById(branchId): any {
        return this.http.get(environment.baseUrl + 'payer/GetPayerForPermileById/' + branchId, this.httpOptions);
    }
    GetPayerForPertripById(branchId): any {
        return this.http.get(environment.baseUrl + 'payer/GetPayerForPertripById/' + branchId, this.httpOptions);
    }
    GetPayerForPerHourById(branchId): any {
        return this.http.get(environment.baseUrl + 'payer/GetPayerForPerHourById/' + branchId, this.httpOptions);
    }
    GetPayerForSalaryById(branchId): any {
        return this.http.get(environment.baseUrl + 'payer/GetPayerForSalaryById/' + branchId, this.httpOptions);
    }
    PayersByBranchId(branchId): any {
       // console.log(environment.baseUrl + "payer/GetPayers1/" + Id);
        return this.http.get(environment.baseUrl + "payer/GetPayers1/" + branchId, this.httpOptions);
    }
    PayerById(Id): any {
       // alert(environment.baseUrl + "payer/" + Id);
        return this.http.get(environment.baseUrl + "payer/" + Id, this.httpOptions);
    }
    PayersBySearch(searchTerm, branchID): any {
        return this.http.get(environment.baseUrl + "payer/search?q=" + searchTerm + '&branchId=' + branchID, this.httpOptions);
    }
    UpdatePayerById(id, request) {
        return this.http.put<Payer>(environment.baseUrl + "payer/" + id, request, this.httpOptions);
    }
    AddCustomimportsetting(Data) {
        return this.http.post(environment.baseUrl + 'payer/customimportsetting', Data, this.httpOptions);
    }
    CustomimportsettingByPayerId(Id): any {
        return this.http.get(environment.baseUrl + "payer/customimportsetting/" + Id, this.httpOptions);
    }
    UpdateCustomimportsettingById(id, request) {
        return this.http.put<CustomImportSettings>(environment.baseUrl + "payer/customimportsetting/" + id, request, this.httpOptions);
    }
    /** End Payer */
    /** Driver */
    AddDriver(Data) {
        return this.http.post(environment.baseUrl + 'driver', Data, this.httpOptions);
    }
    GetDriverByBranchId(branchId): any {
        return this.http.get(environment.baseUrl + 'driver?branchId=' + branchId, this.httpOptions);
    }
    DriverById(Id): any {
        return this.http.get(environment.baseUrl + "driver/" + Id, this.httpOptions);
    }
    UpdateDriverById(id, request) {
        return this.http.put<Driver>(environment.baseUrl + "driver/" + id, request, this.httpOptions);
    }
    DriversBySearch(requestData): any {
        return this.http.get(environment.baseUrl + "driver/search" + requestData, this.httpOptions);
    }
    /** End Driver */
    /** Vehicle */
    AddVehicle(Data) {
        return this.http.post(environment.baseUrl + 'vehicle', Data, this.httpOptions);
    }
    GetVehicleByBranchId(branchId): any {
        return this.http.get(environment.baseUrl + 'vehicle?branchId=' + branchId, this.httpOptions);
    }
    VehicleById(Id): any {
        return this.http.get(environment.baseUrl + "vehicle/" + Id, this.httpOptions);
    }
    AddVehiclePhoto(Data) {
        return this.http.post(environment.baseUrl + 'vehicle/vehiclephoto', Data, this.httpOptions);
    }
    VehiclePhotoByID(Id): any {
        return this.http.get(environment.baseUrl + "vehicle/vehiclephoto/" + Id, this.httpOptions);
    }
    AddLicencePlatePhoto(Data) {
        return this.http.post(environment.baseUrl + 'vehicle/licenceplatephoto', Data, this.httpOptions);
    }
    LicencePlatePhotoByID(Id): any {
        return this.http.get(environment.baseUrl + "vehicle/licenceplatephoto/" + Id, this.httpOptions);
    }
    VehiclesBySearch(searchTerm, branchID): any {
        return this.http.get(environment.baseUrl + "vehicle/vehiclesSearch?q=" + searchTerm + '&branchId=' + branchID, this.httpOptions);
    }
    /** End Vehicle */

    /** Levelofservice */
    AddLevelofservice(Data) {
        return this.http.post(environment.baseUrl + 'master/levelofservice', Data, this.httpOptions);
    }
    GetAllLevelofservices(): any {
        return this.http.get(environment.baseUrl + "master/levelofservices", this.httpOptions);
    }
    LevelofserviceById(Id): any {
        return this.http.get(environment.baseUrl + "master/levelofservice/" + Id, this.httpOptions);
    }
    UpdateLevelOfServieById(id, request) {
        return this.http.put<LevelOfService>(environment.baseUrl + "master/levelofservice/" + id, request, this.httpOptions);
    }
    /** / Levelofservice */

    /** Vehicle Group */
    AddVehicleGroup(Data) {
        return this.http.post(environment.baseUrl + 'master/vehiclegroup', Data, this.httpOptions);
    }
    GetAllVehicleGroup(): any {
        return this.http.get(environment.baseUrl + "master/vehiclegroups", this.httpOptions);
    }
    VehicleGroupById(Id): any {
        return this.http.get(environment.baseUrl + "master/vehiclegroup/" + Id, this.httpOptions);
    }
    UpdateVehicleGroupById(id, request) {
        return this.http.put<VehicleGroup>(environment.baseUrl + "master/vehiclegroup/" + id, request, this.httpOptions);
    }
    /** Vehicle Group */

    /** Capacity Type */
    AddCapacityType(Data) {
        return this.http.post(environment.baseUrl + 'master/capacitytype', Data, this.httpOptions);
    }
    GetAllCapacityType(): any {
        return this.http.get(environment.baseUrl + "master/capacitytypes", this.httpOptions);
    }
    CapacityTypeById(Id): any {
        return this.http.get(environment.baseUrl + "master/capacitytype/" + Id, this.httpOptions);
    }
    UpdateCapacityTypeById(id, request) {
        return this.http.put<VehicleGroup>(environment.baseUrl + "master/capacitytype/" + id, request, this.httpOptions);
    }
    /** Capacity Type */

    /** Insurance Type */
    AddInsuranceType(Data) {
        return this.http.post(environment.baseUrl + 'master/insurancetype', Data, this.httpOptions);
    }
    GetAllInsuranceType(): any {
        return this.http.get(environment.baseUrl + "master/insurancetype", this.httpOptions);
    }
    InsuranceTypeById(Id): any {
        return this.http.get(environment.baseUrl + "master/insurancetype/" + Id, this.httpOptions);
    }
    UpdateInsuranceTypeById(id, request) {
        return this.http.put<VehicleGroup>(environment.baseUrl + "master/insurancetype/" + id, request, this.httpOptions);
    }
    /** Insurance Type */

    /** Patient Type */
    AddPatientType(Data) {
        return this.http.post(environment.baseUrl + 'master/patienttype', Data, this.httpOptions);
    }
    GetAllPatientType(): any {
        return this.http.get(environment.baseUrl + "master/patienttype", this.httpOptions);
    }
    PatientTypeById(Id): any {
        return this.http.get(environment.baseUrl + "master/patienttype/" + Id, this.httpOptions);
    }
    UpdatePatientTypeById(id, request) {
        return this.http.put<any>(environment.baseUrl + "master/patienttype/" + id, request, this.httpOptions);
    }
    /** Patient Type */
    /** Courtesy Calling */
    AddCourtesyCalling(Data) {
        return this.http.post(environment.baseUrl + 'master/courtesycalling', Data, this.httpOptions);
    }
    GetAllCourtesyCalling(): any {
        return this.http.get(environment.baseUrl + "master/courtesycalling", this.httpOptions);
    }
    CourtesyCallingById(Id): any {
        return this.http.get(environment.baseUrl + "master/courtesycalling/" + Id, this.httpOptions);
    }
    UpdateCourtesyCallingById(id, request) {
        return this.http.put<any>(environment.baseUrl + "master/courtesycalling/" + id, request, this.httpOptions);
    }
    /** Courtesy Calling */

    /** Vehicle Condition */
    AddVehicleCondition(Data) {
        return this.http.post(environment.baseUrl + 'master/vehiclecondition', Data, this.httpOptions);
    }
    GetVehiclecondition(): any {
        return this.http.get(environment.baseUrl + "master/vehiclecondition", this.httpOptions);
    }
    GetVehicleConditionID(Id): any {
        return this.http.get(environment.baseUrl + "master/vehiclecondition/" + Id, this.httpOptions);
    }
    UpdateVehicleConditionByID(id, request) {
        return this.http.put<any>(environment.baseUrl + "master/vehiclecondition/" + id, request, this.httpOptions);
    }
    /** Vehicle Condition */

    /** Manual Entry */
    AddManualEntry(Data) {
        return this.http.post(environment.baseUrl + 'manualentry', Data, this.httpOptions);
    }
    GetManualEntries(branchID, startDate?: any, endDate?: any): any {
        return this.http.get(environment.baseUrl + "manualentry?branchId=" + branchID + '&startDate=' + startDate + '&endDate=' + endDate, this.httpOptions);
    }
    UpdateManualEntryTypeById(id, request) {
        return this.http.put<any>(environment.baseUrl + "manualentry/" + id, request, this.httpOptions);
    }
    /** /Manual Entry */

    /** Import File */
    ImportFiles(File): Observable<any> {
        return this.http.post(environment.baseUrl + 'trip/importfiles', File, this.httpOptionsFormData);
    }
    GetFileimports(requestData): any {
        return this.http.post(environment.baseUrl + "trip/fileimports", requestData, this.httpOptions);
    }
    GetFileImportStatus(payerId, branchID, fileImportID): any {
        return this.http.get(environment.baseUrl + "trip/fileImportStatus?payerId=" + payerId + '&branchID=' + branchID + '&fileImportID=' + fileImportID, this.httpOptions);
    }
    GetRawTripsByFileImportID(fileImportID): any {
        return this.http.get(environment.baseUrl + "trip/rawtrips/" + fileImportID, this.httpOptions);
    }
    GetTripsByFileImportID(fileImportID): any {
        return this.http.get(environment.baseUrl + "trip/trips/" + fileImportID, this.httpOptions);
    }
    GetTripsByBranch(requestData): any {
        return this.http.get(environment.baseUrl + "trip/trips" + requestData, this.httpOptions);
    }
    TripsReport(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/tripsReport", requestData, this.httpOptions);
    }
    TripsDriver(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/tripsDriver", requestData, this.httpOptions);
    }
    GetTripsForScheduler(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/tripsForScheduler", requestData, this.httpOptions);
    }
    GetDispatcherTripsSearch(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/dispatchertrips", requestData, this.httpOptions);
    }
    GetSummaryDispatcherTrips(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/summaryDispatcherTrips", requestData, this.httpOptions);
    }
    UpdateTripById(id, request) {
        return this.http.put<any>(environment.baseUrl + "trip/" + id, request, this.httpOptions);
    }
    UpdateDispatcherTripById(id, request) {
        return this.http.put<any>(environment.baseUrl + "trip/updateDispatcherTrip/" + id, request, this.httpOptions);
    }
    UnAssignTrip(id, request) {
        return this.http.put<any>(environment.baseUrl + "trip/unAssignTrip/" + id, request, this.httpOptions);
    }
    GetTripsSummary(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/summary", requestData, this.httpOptions);
    }
    GetTripsSummaryTripDate(branchID, tripDate): any {
        return this.http.get(environment.baseUrl + "trip/summary?branchID=" + branchID + '&' + 'tripDate=' + tripDate, this.httpOptions);
    }
    GetUnableToCancelTrips(startDate?: any, endDate?: any): any {
        return this.http.get<any>(environment.baseUrl + "trip/unableToCancelTrips/?startDate=" + startDate + "&endDate=" + endDate, this.httpOptions);
    }
    Timezones() {
        return this.http.get(environment.baseUrl + "trip/timezones", this.httpOptions);
    }
    getTripImage(tripId) {
        return this.http.get(environment.baseUrl + "app/tripphoto/" + tripId, this.httpOptions);
    }
    /** / Import File */
    /** Assignment */
    AddAssignment(Data) {
        return this.http.post(environment.baseUrl + 'trip/assignment', Data, this.httpOptions);
    }
    UpdateUnAssignReturnlegs(requestData){
        return this.http.post<any>(environment.baseUrl + "trip/UpdateUnAssignReturnlegs", requestData, this.httpOptions);
    }

    GetAssignment(): any {
        return this.http.get(environment.baseUrl + "trip/assignment", this.httpOptions);
    }
    GetAssignmentByAssignDate(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/currentassignment", requestData, this.httpOptions);
    }
    GetAssignmentByBranchID(branchID): any {
        return this.http.get(environment.baseUrl + "trip/assignment?branchID=" + branchID, this.httpOptions);
    }
    GetAssignmentByAssignmentID(id): any {
        return this.http.get(environment.baseUrl + "trip/assignment/" + id, this.httpOptions);
    }
    UpdateAssignementByID(id, request) {
        return this.http.put<any>(environment.baseUrl + "trip/assignment/" + id, request, this.httpOptions);
    }
    UpdateAssignmentTripTime(id, request) {
        return this.http.put<any>(environment.baseUrl + "trip/updateAssignmentTripTime/" + id, request, this.httpOptions);
    }
    DeleteAssignementByID(id) {
        return this.http.delete(environment.baseUrl + "trip/deleteAssignment/" + id, this.httpOptions);
    }

    RemoveHighlightedColor(id)
    {
        return this.http.delete(environment.baseUrl + "trip/deleteHighlightedTrip/" + id, this.httpOptions);
    }
    CalculateMileage(Data): any {
        return this.http.get(environment.baseUrl + 'trip/mileagecalculation?PickupLocationName=' + Data.PickupLocationName +
            "&PickupAddress=" + Data.PickupAddress + "&PickupAddress1=" + Data.PickupAddress1 + "&PickupCity=" + Data.PickupCity +
            "&PickupState=" + Data.PickupState + "&PickupZip=" + Data.PickupZip +
            "&DropoffLocationName=" + Data.DropoffLocationName + "&DropoffAddress=" + Data.DropoffAddress + "&DropoffAddress1=" + Data.DropoffAddress1 +
            "&DropoffCity=" + Data.DropoffCity + "&DropoffState=" + Data.DropoffState + "&DropoffZip=" + Data.DropoffZip, this.httpOptions);
    }
    GetAssignmentDriversByBranchId(branchId): any {
        return this.http.get(environment.baseUrl + 'driver/assignmentdriversbybranchid?branchID=' + branchId, this.httpOptions);
    }
    /** Billing Setting Info */
    AddBillingSettingInfo(Data) {
        return this.http.post(environment.baseUrl + 'billing/billingsettinginfo', Data, this.httpOptions);
    }
    GetBillingSettingInfobyPayerID(payerID): any {
        return this.http.get(environment.baseUrl + "billing/billingsettinginfobypayer/" + payerID, this.httpOptions);
    }
    GetBillingsettinginfobyID(ID): any {
        return this.http.get(environment.baseUrl + "billing/billingsettinginfobypayer/" + ID, this.httpOptions);
    }
    DeleteBillingSettingInfo(ID) {
        return this.http.delete(environment.baseUrl + 'billing/billingsettinginfo/' + ID, this.httpOptions);
    }
    UpdateManualGoogleDrivingTrip(id, request) {
        return this.http.put<any>(environment.baseUrl + "billing/updateManualGoogleDrivingTrip/" + id, request, this.httpOptions);
    }
    UpdateBillingTripTime(id, request) {
        return this.http.put<any>(environment.baseUrl + "billing/updateBillingTripTime/" + id, request, this.httpOptions);
    }
    GetDrivingTimesConfig(branchID){
        return this.http.get(environment.baseUrl + 'billing/DrivingTimesConfig/' + branchID  , this.httpOptions);

    }
    UpdateDrivingTimesConfig(Data,branchID){
        return this.http.put(environment.baseUrl + 'billing/DrivingTimesConfig/'+branchID, Data, this.httpOptions);

    }
    
    GetRatingConfig(branchID){
        return this.http.get(environment.baseUrl + 'billing/RatingConfig/' + branchID  , this.httpOptions);

    }

    UpdateRatingConfig(Data){
        return this.http.post(environment.baseUrl + 'billing/rating', Data, this.httpOptions);
    }
    /** / Billing Setting Info */
    /** Invoice Reports*/
    InvoiceReport(Data) {
        return this.http.post(environment.baseUrl + 'billing/invoiceReport', Data, this.httpOptions);
    }
    ManualGoogleDrivingTrips(Data) {
        return this.http.post(environment.baseUrl + 'billing/manualGoogleDrivingTrips', Data, this.httpOptions);
    }
    InvoiceHeaderReport(Data) {
        return this.http.post(environment.baseUrl + 'billing/invoiceHeaderReport', Data, this.httpOptions);
    }
    GetInvoiceHeaderByID(ID): any {
        return this.http.get(environment.baseUrl + "billing/invoiceHeaderReport/" + ID, this.httpOptions);
    }
    SearchInvoiceHeader(requestData) {
        return this.http.post(environment.baseUrl + 'billing/searchInvoiceHeader', requestData, this.httpOptions);
    }
    InvoiceReportByTripIDs(requestData) {
        return this.http.post(environment.baseUrl + 'billing/invoiceReportByTripIDs', requestData, this.httpOptions);
    }
    InvoiceLineItemByHeaderId(ID): any {
        return this.http.get(environment.baseUrl + "billing/invoiceLineItemByHeaderId/" + ID, this.httpOptions);
    }
    InvoiceReportByInvoiceLineItemIDs(requestData) {
        return this.http.post(environment.baseUrl + 'billing/invoiceReportByInvoiceLineItemIDs', requestData, this.httpOptions);
    }
    /** / Invoice Reports*/

    /** Mile Price Info */
    AddMilePriceInfo(Data) {
        return this.http.post(environment.baseUrl + 'billing/milepriceinfo', Data, this.httpOptions);
    }
    GetMilePriceInfobyBillingSettingInfoID(billingSettingInfoID): any {
        return this.http.get(environment.baseUrl + "billing/milepriceinfobypayer?billingSettingInfoID=" + billingSettingInfoID, this.httpOptions);
    }
    GetMilePriceInfobyPayerID(payerID): any {
        return this.http.get(environment.baseUrl + "billing/milepriceinfobypayer?payerID=" + payerID, this.httpOptions);
    }
    GetMilePriceInfoByID(ID): any {
        return this.http.get(environment.baseUrl + "billing/milepriceinfobyid/" + ID, this.httpOptions);
    }
    DeleteMilePriceInfo(ID) {
        return this.http.delete(environment.baseUrl + 'billing/milepriceinfo/' + ID, this.httpOptions);
    }
    Calculateprice(requestData) {
        return this.http.get(environment.baseUrl + "billing/calculateprice" + requestData, this.httpOptions);
    }
    /** /Mile Price Info  */

    /** Billing Invoice */
    GenerateInvoices(Data) {
        return this.http.post(environment.baseUrl + 'billing/generateinvoices', Data, this.httpOptions);
    }
    /** / Billing Invoice */

    /** Reports */
    FileUpload(File): Observable<any> {
        return this.http.post(environment.baseUrl + 'trip/sendreport', File, this.httpOptionsFormData);
    }

    /** /Reports */

    /** Template Scheduling */
    AddTemplateSchedulingConfiguration(requestData) {
        return this.http.post(environment.baseUrl + 'trip/templateSchedulingConfiguration', requestData, this.httpOptions);
    }
    GetTemplateSchedulingLegsByID(id): any {
        return this.http.get(environment.baseUrl + "trip/templateSchedulingLegs/" + id, this.httpOptions);
    }
    LoadTemplates(requestData): any {
        return this.http.post(environment.baseUrl + "trip/loadTemplates", requestData, this.httpOptions);
    }
    LoadSameTemplates(requestData): any {
        return this.http.post(environment.baseUrl + "trip/loadsametemplates", requestData, this.httpOptions);
    }
    LoadMatchingTemplatesTrips(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/loadMatchingTemplatesTrips", requestData, this.httpOptions);
    }
    DeleteTemplateLegs(requestData): any {
        return this.http.post<any>(environment.baseUrl + "trip/deleteTemplateLegs", requestData, this.httpOptions);
    }
    UpdateTemplateSchedulingConfigurationByID(id, request) {
        return this.http.put<Zone>(environment.baseUrl + "trip/templateSchedulingConfiguration/" + id, request, this.httpOptions);
    }
    AddLegTemplateByTempleteID(id, request){
        return this.http.put<Zone>(environment.baseUrl + "trip/AddLegTemplateByTempleteID/" + id, request, this.httpOptions);
 
    }
    /** / Template Scheduling */

    /** / Vehical Moving On Map */
    GetCurrentLatLongPosition(vehicalData): any {
        return this.http.post(environment.baseUrl + "app/getMap", vehicalData, this.httpOptions);
    }
    GetCurrentPositionInfo(locationData): any {
        return this.http.post(environment.baseUrl + "app/mapDetail/", locationData, this.httpOptions);
    }
    GetCurrentAddress(locationData): any {
        return this.http.post(environment.baseUrl + "app/mapAddressByLatLong/", locationData, this.httpOptions);
    }
    GetDriverList(data) {
        return this.http.post(environment.baseUrl + "app/driversByBranchID", data, this.httpOptions);
    }
    GetCurrentInfoByDriverID(driverID) {
        return this.http.get(environment.baseUrl + "app/driverstatusdetail/" + driverID, this.httpOptions);
    }
    NearDriversSearchLocation(requestData) {
        return this.http.post(environment.baseUrl + "app/nearDriversSearchLocation", requestData, this.httpOptions);
    }
    LatitudeAndLongitudeByAddress(requestData) {
        return this.http.post(environment.baseUrl + "app/latitudeAndLongitudeByAddress", requestData, this.httpOptions);
    }
    /** / Vehical Moving On Map */

    /** Payroll Configuration */
    DefaultPayrollConfiguration(requestData) {
        return this.http.post(environment.baseUrl + "payroll/addpayrolldefaultconfig", requestData, this.httpOptions);
    }

    GetDefaultPayrollByBranchId(branchID) {
        return this.http.get(environment.baseUrl + "payroll/payrolldefaultconfigs?branchId=" + branchID, this.httpOptions);
    }

    GetDefaultPayrollByPayrollID(payrollID) {
        return this.http.get(environment.baseUrl + "payroll/payrolldefaultconfig/" + payrollID, this.httpOptions);
    }

    UpdateDefaultPayrollConfiguration(requestData) {
        return this.http.put(environment.baseUrl + "payroll/payrolldefaultconfig/" + requestData.payrollDefaultConfigID, requestData, this.httpOptions);
    }
    SearchEmployees(searchTerm, branchID) {
        if (typeof searchTerm == 'string' && searchTerm.length >= 1) {
            return this.http.get(environment.baseUrl + "payroll/searchemployees?q=" + searchTerm + "&branchId=" + branchID, this.httpOptions);
        }
        return empty();
    }
    /** Payroll Default Config  */      
    AddPayrollConfigPerMiles(requestData) {
        return this.http.post(environment.baseUrl + "payroll/payrollconfigpermile", requestData, this.httpOptions);
    }
    AddPayrollConfigPerMilePayRoll(requestData) {
        return this.http.post(environment.baseUrl + "payroll/payrollConfigPerMilePayRoll", requestData, this.httpOptions);
    }

    GetPayrollConfigPerMileNew(branchId, payerId, IsDefault, IsHigh, IsLow){
      //  console.log("YY");
        return this.http.get(environment.baseUrl + "payroll/payrollConfigPerMileNew?branchId=" + branchId + "&payerid="+ payerId +  "&IsDefault=" + IsDefault + "&IsHigh=" + IsHigh + "&IsLow=" + IsLow, this.httpOptions);
    }
    GetPayrollConfigPerMileByBranchId(branchID) {
        return this.http.get(environment.baseUrl + "payroll/payrollConfigPerMile?branchId=" + branchID, this.httpOptions);
    }
    DeletePayrollConfigPerMile(ID) {
        return this.http.delete(environment.baseUrl + 'payroll/payrollConfigPerMile/' + ID, this.httpOptions);
    }
    GetPayrollDefaultSetting(requestData) {
        return this.http.post(environment.baseUrl + "payroll/payrollDefaultSetting", requestData, this.httpOptions);
    }
    /**  Employee Payroll */
    AddEmployeePayroll(requestData) {
        return this.http.post(environment.baseUrl + "payroll/addEmployeePayroll", requestData, this.httpOptions);
    }
    UpdateEmployeePayrollByID(id, request) {
        return this.http.put<Zone>(environment.baseUrl + "payroll/employeePayroll/" + id, request, this.httpOptions);
    }
    GetPayrollConfigPerMileDefaultSetting(requestData) {
        return this.http.post(environment.baseUrl + "payroll/payrollConfigPerMileDefaultSetting", requestData, this.httpOptions);
    }
    //GetLeveofserviceid
    GetLeveofserviceid(requestData) {
        return this.http.post(environment.baseUrl + "payroll/GetLeveofserviceid", requestData, this.httpOptions);
    }
    AddEmployeePayrollMilePrice(requestData) {
        return this.http.post(environment.baseUrl + "payroll/employeePayrollMilePrice", requestData, this.httpOptions);
    }
    GetPayrollEmployeesByBranchId(branchID) {
        return this.http.get(environment.baseUrl + "payroll/payrollEmployees?branchId=" + branchID, this.httpOptions);
    }
    GetEmployeePayrollByID(employeePayrollID) {
        return this.http.get(environment.baseUrl + "payroll/employeePayroll/" + employeePayrollID, this.httpOptions);
    }
    DeleteEmployeePayrollMilePrice(ID) {
        return this.http.delete(environment.baseUrl + 'payroll/employeePayrollMilePrice/' + ID, this.httpOptions);
    }
    /** Geo Coding */
    AddGeoCodingConfig(Data) {
        return this.http.post(environment.baseUrl + 'master/geoCodingConfig', Data, this.httpOptions);
    }
    UpdateGeoCodingConfigById(id, request) {
        return this.http.put<Payer>(environment.baseUrl + "master/geoCodingConfig/" + id, request, this.httpOptions);
    }
    GetGeoCodingConfigByBranchId(branchID): any {
        return this.http.get(environment.baseUrl + "master/geoCodingConfig?branchId=" + branchID, this.httpOptions);
    }
    GetGeoCodingConfigById(Id): any {
        return this.http.get(environment.baseUrl + "master/geoCodingConfig/" + Id, this.httpOptions);
    }
    /** Geo Coding Module */
    AddGeoCodingModule(Data) {
        return this.http.post(environment.baseUrl + 'master/geocodingmodule', Data, this.httpOptions);
    }
    UpdateGeoCodingModuleById(id, request) {
        return this.http.put<Payer>(environment.baseUrl + "master/geocodingmodule/" + id, request, this.httpOptions);
    }
    GetAllGeoCodingModules(): any {
        return this.http.get(environment.baseUrl + "master/geocodingmodule", this.httpOptions);
    }
    GetGeoCodingModuleById(Id): any {
        return this.http.get(environment.baseUrl + "master/geocodingmodule/" + Id, this.httpOptions);
    }
    /** Add Geo Coding Functionality */
    AddGeoCodingFunctionality(Data) {
        return this.http.post(environment.baseUrl + 'master/geocodingfunctionality', Data, this.httpOptions);
    }
    UpdateGeoCodingFunctionalityById(id, request) {
        return this.http.put<Payer>(environment.baseUrl + "master/geocodingfunctionality/" + id, request, this.httpOptions);
    }
    GetAllGeoCodingFunctionalities(): any {
        return this.http.get(environment.baseUrl + "master/geocodingfunctionality", this.httpOptions);
    }
    GetGeoCodingFunctionalityById(Id): any {
        return this.http.get(environment.baseUrl + "master/geocodingfunctionality/" + Id, this.httpOptions);
    }
    GetGeoCodingFunctionalityByModules(Id): any {
        return this.http.get(environment.baseUrl + "master/geoCodingFunctionalityByModules/" + Id, this.httpOptions);
    }
    /** Payroll Amount Calculation */
    PayrollAmountCalculation(requestData) {
        return this.http.post(environment.baseUrl + 'payroll/payrollAmountCalculation', requestData, this.httpOptions);
    }
//
    PayrollReport(requestData) {
    return this.http.post(environment.baseUrl + 'payroll/PayrollReport', requestData, this.httpOptions);
      }
    addEmployeePayrollNew(data){
       
        return this.http.post(environment.baseUrl + 'payroll/addEmployeePayrollNew', data, this.httpOptions);
    }

    employeePayrollMilePriceNew(data){
        return this.http.post(environment.baseUrl + 'payroll/employeePayrollMilePriceNew', data, this.httpOptions);
    }

    addEmployeePayrollDefaultConfigCustome(data){
       // console.log("hi",environment.baseUrl + data);
        return this.http.post(environment.baseUrl + 'payroll/addEmployeePayrollDefaultConfigCustome', data, this.httpOptions);
    }

    payrollConfigPerMilePayRollCustome(data){
        return this.http.post(environment.baseUrl + 'payroll/payrollConfigPerMilePayRollCustome', data, this.httpOptions);
    }

    GetPayrollConfigPerMileView(data) {
        return this.http.get(environment.baseUrl + "payroll/payrollConfigPerMileView?branchId=" + data, this.httpOptions);
    }
    GetPayrollConfigEmployee(branchId) {
        return this.http.get(environment.baseUrl + 'payroll/employeesPayrollPrice?branchId=' + branchId, this.httpOptions);

    }
    payrollConfigPerMileNewByUserID(branchId, UserId, payerId, IsCustome) {
        return this.http.get(environment.baseUrl + 'payroll/payrollConfigPerMileNewByUserID?branchId=' + branchId + "&UserId=" + UserId + "&payerid=" + payerId + "&IsCustome=" + IsCustome, this.httpOptions);

    }

    printReportByTripIDs(requestData):any {
        return this.http.post(environment.baseUrl + 'trip/allTripsReportsForPDF',requestData, this.httpOptions);
    }

}






