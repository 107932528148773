import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { COWTUX_CONFIG } from '@cowtux/services/config.service';

@NgModule()
export class COWTUXModule
{
    constructor(@Optional() @SkipSelf() parentModule: COWTUXModule)
    {
        if ( parentModule )
        {
            throw new Error('COWTUXModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders
    {
        return {
            ngModule : COWTUXModule,
            providers: [
                {
                    provide : COWTUX_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
