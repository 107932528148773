export class Login {
    username: string;
    password: string;
    auth_token: string;
}

export class UserRole {
    userRoleID: number;
    userRoleName: string;
    memberAccess?: boolean;
    driverAccess?: boolean;
    vehicleAccess?: boolean;
    payerAccess?: boolean;
    dispatcherAccess?: boolean;
    manualEntryAccess?: boolean;
    billingAccess?: boolean;
    reportAccess?: boolean;
    adminAccess?: boolean;
    configAccess?: boolean;
    description: string;
}

export class ChangePassword {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string
}

export class UserProfilePhoto {
    userProfilePhotoID: number;
    id: string;
    userProfileImage: any;
}