import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { navigation } from 'app/navigation/navigation';
import { environment } from 'environments/environment';
import { Login, UserRole, ChangePassword, UserProfilePhoto } from 'app/main/apps/shared/models/Login';
import { RestClientBaseService } from './rest-client-base.service';
import { UserProfile } from 'app/main/apps/shared/models/UserProfile';
import { CowtMasterService } from './cowt-master.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends RestClientBaseService {

    constructor(
        private http: HttpClient,
        private _cowtMasterService: CowtMasterService
    ) {
        super();
    }

    UserRegister(request): Observable<Login> {
        return this.http.post<any>(environment.baseUrl + "Account/register", request, this.httpOptions);
    }

    UserLogin(request: Login): Observable<Login> {
        return this.http.post<Login>(environment.baseUrl + "account/login", request, this.httpOptions);
    }

    logout() {
        localStorage.removeItem('dispatcherSearchData');
        return localStorage.removeItem('auth_token');
    }

    ConfirmEmail(userId: string, code: string): any {
        return this.http.get(environment.baseUrl + "account/confirmEmail?userId=" + userId + "&code=" + code);
    }

    ChangePassword(request: ChangePassword): Observable<ChangePassword> {
        return this.http.post<ChangePassword>(environment.baseUrl + "account/changepassword", request, this.httpOptions);
    }
    /** 2-fa */
    EnableTwofa(): any {
        return this.http.get(environment.baseUrl + "account/2fa/enable", this.httpOptions);
    }

    AddEnableTwofa(request: Login): Observable<Login> {
        return this.http.post<Login>(environment.baseUrl + "account/2fa/enable", request, this.httpOptions);
    }
    TwofaLogin(request: Login): Observable<Login> {
        return this.http.post<Login>(environment.baseUrl + "account/2fa/login", request, this.httpOptions);
    }
    /** / 2-fa */

    /** User Profile */
    GetUserProfile(): Observable<UserProfile> {
        return this.http.get<UserProfile>(environment.baseUrl + "account/profile", this.httpOptions);
    }
    GetAllUsers(): any {
        return this.http.get(environment.baseUrl + "Account/users", this.httpOptions);
    }
    SearchUsers(requestData): any {
        return this.http.get(environment.baseUrl + "Account/search" + requestData, this.httpOptions);
    }
    UpdateUserProfile(request: UserProfile): Observable<UserProfile> {
        return this.http.put<UserProfile>(environment.baseUrl + "account/profile", request, this.httpOptions);
    }
    UpdateUserProfileBySuperAdmin(request: UserProfile): Observable<UserProfile> {
        return this.http.put<UserProfile>(environment.baseUrl + "account/updateUserBySuperAdmin", request, this.httpOptions);
    }
    ProfileByUserID(request): any {
        return this.http.post<any>(environment.baseUrl + "account/profileByUserId", request, this.httpOptions);
    }
    CompanyAndBranchDetails(id): any {
        return this.http.get<any>(environment.baseUrl + "account/companyandBranchDetail?Id=" + id, this.httpOptions);
    }
    AddUserProfilePhoto(Data) {
        return this.http.post(environment.baseUrl + 'account/userprofilephoto', Data, this.httpOptions);
    }
    UserProfilePhotoByID(Id): any {
        return this.http.get(environment.baseUrl + "account/userprofilephoto/" + Id, this.httpOptions);
    }
    ResendActivationMail(requestData): any {
        return this.http.get(environment.baseUrl + "Account/resendActivationMail/" + requestData, this.httpOptions);
    }
    GetCompany(): any {
        return this.http.get(environment.baseUrl + "companies", this.httpOptions);
    }
    /** / User Profile */

    /** Navigation Permission */
    GetNavigation(): Observable<any> {
        let permittedNavigation: any = JSON.parse(JSON.stringify(navigation));
        if (localStorage.getItem('auth_token')) {
            permittedNavigation.forEach(element => {
                if (element.children != null) {
                    element.children = element.children.filter(x => {
                        if (x.requiredAccess == null || x.requiredAccess == '')
                            return true;

                        return this._cowtMasterService.rightMatch([x.requiredAccess]) == true;
                    });
                }
            });
            permittedNavigation = permittedNavigation.filter(x => {
                if (x.children == null || x.children.length == 0)
                    return false;
                return true;
            });
        }

        return of(permittedNavigation);
    }
    /** / Navigation Permission */

    /** Add User Role */
    AddUserRole(request: UserRole): Observable<UserRole> {
        return this.http.post<UserRole>(environment.baseUrl + "account/userrole", request, this.httpOptions);
    }
    UpdateUserRole(request: UserRole): Observable<UserRole> {
        return this.http.put<UserRole>(environment.baseUrl + "account/userrole", request, this.httpOptions);
    }
    GetUserRoles() {
        return this.http.get<any>(environment.baseUrl + "account/userroles", this.httpOptions);
    }
    GetUserRoleByID(userRoleID) {
        return this.http.get<any>(environment.baseUrl + "account/userrolebyid?Id=" + userRoleID, this.httpOptions);
    }
    GetUserRolePermission(comanyId, branchId) {
        return this.http.get<any>(environment.baseUrl + "account/userrole/?compId=" + comanyId + "&" + "branchId=" + branchId, this.httpOptions);
    }
    DeleteUserrole(Id) {
        return this.http.delete(environment.baseUrl + 'account/deleteuserrole/' + Id, this.httpOptions);
    }
    /** / Add User Role */
    /**  Forgot Password*/
    ForgotPassword(requestData) {
        return this.http.post(environment.baseUrl + 'account/forgotPassword', requestData, this.httpOptions);
    }
    ResetPassword(requestData) {
        return this.http.post(environment.baseUrl + 'account/resetPassword/', requestData, this.httpOptions);
    }
    /** / Forgot Password*/
    /** Set Password */
    SetPassword(requestData) {
        return this.http.post(environment.baseUrl + 'account/setPassword/', requestData, this.httpOptions);
    }
    /** / Set Password */

}