import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-generic-message-popup',
  templateUrl: './generic-message-popup.component.html',
  styleUrls: ['./generic-message-popup.component.scss']
})
export class GenericMessagePopupComponent implements OnInit {
  message: string = ""
  cancelButtonText = "OK"
  RateValues:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GenericMessagePopupComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      this.RateValues=data.result;
    }
    this.dialogRef.updateSize('400vw', '400vw')
  }


  ngOnInit() {
   // console.log(this.RateValues)
    if(this.RateValues!=null ||this.RateValues!=undefined)
    document.getElementById("UserAddedData").innerHTML=(this.RateValues);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
