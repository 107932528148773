import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { COWTUXConfigService } from '@cowtux/services/config.service';
import { COWTUXNavigationService } from '@cowtux/components/navigation/navigation.service';
import { COWTUXSidebarService } from '@cowtux/components/sidebar/sidebar.service';
import { COWTUXSplashScreenService } from '@cowtux/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@cowtux/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    COWTUXConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {COWTUXConfigService} _cowtuxConfigService
     * @param {COWTUXNavigationService} _cowtuxNavigationService
     * @param {COWTUXSidebarService} _cowtuxSidebarService
     * @param {COWTUXSplashScreenService} _cowtuxSplashScreenService
     * @param {FuseTranslationLoaderService} _cowtuxTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _cowtuxConfigService: COWTUXConfigService,
        private _cowtuxNavigationService: COWTUXNavigationService,
        private _cowtuxSidebarService: COWTUXSidebarService,
        private _cowtuxSplashScreenService: COWTUXSplashScreenService,
        private _cowtuxTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _authService: AuthService
    ) {
        // Get default navigation
        this._authService.GetNavigation()
            .subscribe((nav: any) => {
                this.navigation = nav;
                // Register the navigation to the service

                this._cowtuxNavigationService.register('main', this.navigation);

                // Set the main navigation as our current navigation
                this._cowtuxNavigationService.setCurrentNavigation('main');
            });

        // Set the main navigation as our current navigation
        this._cowtuxNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._cowtuxTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._cowtuxConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                config.layout.navbar.primaryBackground = localStorage.getItem('primaryBackgroundOption') || 'light-blue-900';
                config.layout.navbar.secondaryBackground = localStorage.getItem('secondaryBackgroundOption') || 'cowtux-white-900';
                this.COWTUXConfig = config;

                // Boxed
                if (this.COWTUXConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.COWTUXConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._cowtuxSidebarService.getSidebar(key).toggleOpen();
    }
}
