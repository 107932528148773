import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable()
export class MomentUtcDateAdapter extends MomentDateAdapter {

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
    }

    createDate(year: number, month: number, date: number): Moment {
        // Moment.js will create an invalid date if any of the components are out of bounds, but we
        // explicitly check each case so we can throw more descriptive errors.
        if (month < 0 || month > 11) {
            throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
        }


        if (date < 1) {
            throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
        }

        let result = moment.utc({ year, month, date }).locale(this.locale);

        // If the result isn't valid, the date must have been out of bounds for this month.
        if (!result.isValid()) {
            throw Error(`Invalid date "${date}" for month with index "${month}".`);
        }

        return result;
    }

    public format(date: moment.Moment, displayFormat: string): string {
        const locale = 'en-US';
        const format = 'MM/DD/YYYY';

        return date.locale(locale).format(format);
    }
}

export class CustomDateAdapter extends NativeDateAdapter {
    useUtcForDisplay = false;
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }
}

export let MY_Date_Format = {
    parse: {
        dateInput: 'MM/DD/YYYY',//'DD/MM/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',//'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'MM/DD/YYYY',//'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};
