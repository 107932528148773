import { COWTUXNavigation } from '@cowtux/types';

export const navigation: COWTUXNavigation[] = [
    // Applications
    {
        id: 'applications',
        title: 'Overview',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        requiredAccess: 'Admin',
        children: [
            {
                id: 'members',
                title: 'Members',
                translate: 'NAV.MEMBERS',
                type: 'item',
                icon: 'person',
                url: '/apps/membersmodule/members',
                requiredAccess: 'memberAccess',
            },
            {
                id: 'drivers',
                title: 'Drivers',
                translate: 'NAV.DRIVERS',
                type: 'item',
                img: '/assets/images/backgrounds/Driver_W.svg',
                url: '/apps/driversmodule/drivers',
                requiredAccess: 'driverAccess'
            },
            {
                id: 'vehicles',
                title: 'Vehicles',
                translate: 'NAV.VEHICLES',
                type: 'item',
                icon: 'directions_car',
                url: '/apps/vehiclesmodule/vehicles',
                requiredAccess: 'vehicleAccess'
            },
            {
                id: 'payers',
                title: 'Payers',
                translate: 'NAV.PAYERS',
                type: 'item',
                icon: 'attach_money',
                url: '/apps/financemodule/payers',
                requiredAccess: 'payerAccess'
            },
        ]
    },
    // Dispatch Management
    {
        id: 'dispatchManagement',
        title: 'Dispatch Management',
        translate: 'NAV.DISPATCHMANAGEMENT',
        type: 'group',
        icon: 'apps',
        requiredAccess: 'adminAccess',
        children: [
            {
                id: 'dispatcher',
                title: 'Dispatcher',
                translate: 'NAV.DISPATCHER',
                type: 'item',
                img: '/assets/images/backgrounds/Dispatcher_W.svg',
                url: '/apps/dispatchermodule/dispatcher',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'fileImport',
                title: 'File Import',
                translate: 'NAV.FILEIMPORT',
                type: 'item',
                icon: 'attach_file',
                url: '/apps/dispatchermodule/trip-import',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'scheduler',
                title: 'Scheduler',
                translate: 'NAV.SCHEDULER',
                type: 'item',
                icon: 'assignment_turned_in',
                url: '/apps/dispatchermodule/assignment',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'vehiclesOnMap',
                title: 'Map',
                translate: 'NAV.VEHICLESONMAP',
                type: 'item',
                icon: 'location_on',
                url: '/apps/dispatchermodule/vehicles-on-map',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'zones',
                title: 'Zones',
                translate: 'NAV.ZONES',
                type: 'item',
                img: '/assets/images/backgrounds/Zone_W.svg',
                url: '/apps/dispatchermodule/zones',
                requiredAccess: 'adminAccess',
            },
        ]
    },
    // Payroll
    {
        id: 'payroll',
        title: 'Payroll',
        translate: 'NAV.PAYROLL',
        type: 'group',
        icon: 'money',
        requiredAccess: 'adminAccess',
        children: [
            {
                id: 'defaultPayrollConfig',
                //title: 'Default Payroll Config',
                title: 'Predefined Payroll Levels',
                translate: 'NAV.DEFAULPAYROLLTCONFIG',
                type: 'item',
                img: '/assets/images/backgrounds/ProcessBill_W.svg',
                url: '/apps/payrollmodule/default-payroll-config',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'employeesPayrollSettings',
                //title: 'Employees Payroll Settings',
                title: 'Employee Payroll Settings',
                translate: 'NAV.EMPLOYEESPAYROLLSETTINGS',
                type: 'item',
                img: '/assets/images/backgrounds/ProcessBill_W.svg',
                url: '/apps/payrollmodule/employees-payroll',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'empPayrollAmount',
                //title: 'Payroll Amounts',
                title: 'View Employee Pay Level',
                translate: 'NAV.EMPPAYROLLAMOUNTS',
                type: 'item',
                img: '/assets/images/backgrounds/ProcessBill_W.svg',
                url: '/apps/payrollmodule/employees-payroll-amount',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'payrollAmounts',
                //title: 'Payroll Amounts',
                title: 'View Employee Pay',
                translate: 'NAV.PAYROLLAMOUNTS',
                type: 'item',
                img: '/assets/images/backgrounds/ProcessBill_W.svg',
                url: '/apps/payrollmodule/payroll-amounts',
                requiredAccess: 'adminAccess'
            }

        ]
    },
    // Finance Management
    {
        id: 'financeManagement',
        title: 'Billing',
        translate: 'NAV.FINANCEMANAGEMENT',
        type: 'group',
        icon: 'money',
        requiredAccess: 'SuperAdmin',
        children: [
            {
                id: 'processBilling',
                title: 'Process Billing',
                translate: 'NAV.PROCESSBILLING',
                type: 'item',
                img: '/assets/images/backgrounds/ProcessBill_W.svg',
                url: '/apps/financemodule/by-trips',
                requiredAccess: 'payerAccess'
            },
            {
                id: 'unableToCancel',
                title: 'Unable To Cancel',
                translate: 'NAV.UNABLETOCANCEL',
                type: 'item',
                icon: 'developer_board',
                url: '/apps/financemodule/unable-to-cancel',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'emulator',
                title: 'Emulator',
                translate: 'NAV.EMULATOR',
                type: 'item',
                icon: 'developer_board',
                url: '/apps/financemodule/emulator',
                requiredAccess: 'adminAccess'
            }
        ]
    },
    // Report Management
    {
        id: 'reportManagement',
        title: 'Reports',
        translate: 'NAV.REPORTMANAGEMENT',
        type: 'group',
        icon: 'note',
        requiredAccess: 'adminAccess',
        children: [
            {
                id: 'dailyTripsLog',
                title: 'Daily Trips Log',
                translate: 'NAV.DAILYTRIPSLOG',
                type: 'item',
                icon: 'schedule',
                url: '/apps/reportsmodule/daily-trips-log',
                requiredAccess: 'adminAccess'
            },
            // {
            //     id: 'driverTripsLog',
            //     title: 'Driver Trips Log',
            //     translate: 'NAV.DRIVERTRIPSLOG',
            //     type: 'item',
            //     icon: 'person',
            //     url: '/apps/reportsmodule/driver-trips-log',
            //     requiredAccess: 'adminAccess'
            // },
            {
                id: 'driverReports',
                title: 'Driver Reports',
                translate: 'NAV.DRIVERREPORTS',
                type: 'item',
                img: '/assets/images/backgrounds/Driver_W.svg',
                url: '/apps/reportsmodule/driver-report',
                requiredAccess: 'adminAccess'
            },
            // {
            //     id: 'auditReport',
            //     title: 'Audit Report',
            //     translate: 'NAV.AUDITREPORT',
            //     type: 'item',
            //     icon: 'report',
            //     url: '/apps/reportsmodule/audit-report',
            //     requiredAccess: 'adminAccess'
            // },
            //disptacher Report Management
            {
                id: 'disptacherReportManagement',
                title: 'Dispatcher Reports',
                translate: 'NAV.DISPATCHERREPORTMANAGEMENT',
                type: 'collapsable',
                img: '/assets/images/backgrounds/dispatcher_reports_icon.svg',
            //   img:'/assets/images/backgrounds/user_white.svg',
                icon:'description',
                requiredAccess: 'adminAccess',
                children: [
                    {
                        id: 'unassignedTripsReports',
                        title: 'Unassigned Trips',
                        translate: 'NAV.UNASSIGNEDTRIPSREPORTS',
                        type: 'item',
                        img: '/assets/images/backgrounds/dispatcher_reports_icon.svg',
                        url: '/apps/reportsmodule/unassigned-trips-reports',
                        requiredAccess: 'adminAccess'
                    },
                    {
                        id: 'allTripsReports',
                        title: 'All Trips',
                        translate: 'NAV.ALLTRIPSREPORTS',
                        type: 'item',
                        img: '/assets/images/backgrounds/dispatcher_reports_icon.svg',
                        url: '/apps/reportsmodule/all-trips-reports',
                        requiredAccess: 'adminAccess'
                    },
                ]
                // Config Settings (SuperAdmin)
            }

        ]
    },
    //disptacher Report Management
    // {
    //     id: 'disptacherReportManagement',
    //     title: 'Dispatcher Reports',
    //     translate: 'NAV.DISPATCHERREPORTMANAGEMENT',
    //     type: 'group',
    //     icon: 'note',
    //     requiredAccess: 'adminAccess',
    //     children: [
    //         {
    //             id: 'unassignedTripsReports',
    //             title: 'Unassigned Trips',
    //             translate: 'NAV.UNASSIGNEDTRIPSREPORTS',
    //             type: 'item',
    //             img: '/assets/images/backgrounds/Driver_W.svg',
    //             url: '/apps/reportsmodule/unassigned-trips-reports',
    //             requiredAccess: 'adminAccess'
    //         },
    //         {
    //             id: 'allTripsReports',
    //             title: 'All Trips',
    //             translate: 'NAV.ALLTRIPSREPORTS',
    //             type: 'item',
    //             img: '/assets/images/backgrounds/Driver_W.svg',
    //             url: '/apps/reportsmodule/all-trips-reports',
    //             requiredAccess: 'adminAccess'
    //         },
    //     ]

    // },
    // Config Settings (SuperAdmin)
    {
        id: 'configSettings',
        title: 'Config Settings',
        translate: 'NAV.CONFIGSETTINGS',
        type: 'group',
        icon: 'apps',
        requiredAccess: 'SuperAdmin',
        children: [
            {
                id: 'userManagement',
                title: 'User Management',
                translate: 'NAV.USERMANAGEMENT',
                type: 'item',
                icon: 'people',
                url: '/apps/configmodule/users',
                requiredAccess: 'SuperAdmin'
            },
            {
                id: 'companies',
                title: 'Companies',
                translate: 'NAV.COMPANIES',
                type: 'item',
                icon: 'business',
                url: '/apps/configmodule/companies',
                requiredAccess: 'SuperAdmin'
            },
            {
                id: 'userRoles',
                title: 'User Roles',
                translate: 'NAV.USERROLES',
                type: 'item',
                img: '/assets/images/backgrounds/user_white.svg',
                url: '/apps/configmodule/user-roles',
                requiredAccess: 'SuperAdmin'
            },
            {
                id: 'masterManagement',
                title: 'Master Management',
                translate: 'NAV.MASTERMANAGEMENT',
                type: 'collapsable',
                icon: 'group_work',
                requiredAccess: 'SuperAdmin',
                children: [
                    {
                        id: 'countries',
                        title: 'Countries',
                        translate: 'NAV.COUNTRIES',
                        type: 'item',
                        icon: 'home',
                        url: '/apps/mastermodule/countries',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'states',
                        title: 'States',
                        translate: 'NAV.STATES',
                        type: 'item',
                        icon: 'account_balance',
                        url: '/apps/mastermodule/states',
                        requiredAccess: 'SuperAdmin',
                    },
                    {
                        id: 'levelsOfService',
                        title: 'Levels Of Service',
                        translate: 'NAV.LEVELSOFSERVICE',
                        type: 'item',
                        img: '/assets/images/backgrounds/Speedometer_W.svg',
                        url: '/apps/mastermodule/level-of-services',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'vehicleGroups',
                        title: 'Vehicle Groups',
                        translate: 'NAV.VEHICLEGROUPS',
                        type: 'item',
                        icon: 'directions_car',
                        url: '/apps/mastermodule/vehicle-groups',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'capacityTypes',
                        title: 'Capacity Types',
                        translate: 'NAV.CAPACITYTYPES',
                        type: 'item',
                        img: '/assets/icons/icons/Groups W.svg',
                        url: '/apps/mastermodule/capacity-types',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'insuranceTypes',
                        title: 'Insurance Types',
                        translate: 'NAV.INSURANCETYPES',
                        type: 'item',
                        img: '/assets/icons/icons/Insurance W.svg',
                        url: '/apps/mastermodule/insurance-types',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'patientTypes',
                        title: 'Patient Types',
                        translate: 'NAV.PATIENTTYPES',
                        type: 'item',
                        icon: 'hotel',
                        url: '/apps/mastermodule/patient-types',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'courtesyCalling',
                        title: 'Courtesy Calling',
                        translate: 'NAV.COURTESYCALLING',
                        type: 'item',
                        icon: 'phone_callback',
                        url: '/apps/mastermodule/courtesy-callings',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'vehicleConditions',
                        title: 'Vehicle Conditions',
                        translate: 'NAV.VEHICLECONDITIONS',
                        type: 'item',
                        img: '/assets/images/backgrounds/car_white.svg',
                        url: '/apps/mastermodule/vehicle-conditions',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'geoCodingModules',
                        title: 'Geo Coding Modules',
                        translate: 'NAV.GEOCODINGMODULES',
                        type: 'item',
                        icon: 'settings_applications',
                        url: '/apps/mastermodule/geocodingmodules',
                        requiredAccess: 'SuperAdmin'
                    },
                    {
                        id: 'geoCodingFunctionalities',
                        title: 'Geo Coding Functionalities',
                        translate: 'NAV.GEOCODINGFUNCTIONALITIES',
                        type: 'item',
                        icon: 'settings_applications',
                        url: '/apps/mastermodule/geocodingfunctionalities',
                        requiredAccess: 'SuperAdmin'
                    }
                ]
            }
        ]
    },
    // Geo Coding Config Settings
    {
        id: 'configSettings',
        title: 'Config Settings',
        translate: 'NAV.CONFIGSETTINGS',
        type: 'group',
        icon: 'apps',
        requiredAccess: 'adminAccess',
        children: [
            {
                id: 'geoCodingConfig',
                title: 'Geo Coding Config',
                translate: 'NAV.GEOCODINGCONFIG',
                type: 'item',
                icon: 'assignment_turned_in',
                url: '/apps/configmodule/geo-coding-config',
                requiredAccess: 'adminAccess'
            },
            {
                id: 'drivingTimesConfig',
                title: 'Driving Times Config',
                translate: 'NAV.DRIVNGTIMECONFIG',
                type: 'item',
                icon: 'assignment_turned_in',
                url: '/apps/configmodule/driving-times-config',
                requiredAccess: 'adminAccess'
            },
        ]
    }
];
