import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CowtMasterService {

    constructor() { }

    rightMatch(allowedRoles): boolean {
        var isMatch = false;
        let accessRole = JSON.parse(localStorage.getItem('roles'));
        if (accessRole !== null) {
            if (accessRole[allowedRoles] == true) {
                isMatch = true;
            }
            return isMatch;
        }
    }
    GetCurrentCompanyId(): string {
        return localStorage.getItem("companyID");
    }
    GetCurrentBranchId(): string {
        return localStorage.getItem("branchID");
    }
    GetCurrentUserEmail(): string {
        return localStorage.getItem("userEmail");
    }
    checkCareOnWheelName(): string {
        return 'Care On Wheels Transportation';
    }
}
