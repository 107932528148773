// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    GoogleMapeKey: 'AIzaSyC7GBfk0qsAvJaR9tLtMTOBAWUJDUDDiqQ',
    production: false,
    hmr: false,
    //baseUrl: 'http://localhost:5000/api/',
////
// baseUrl: 'http://97.107.133.47:5000/api/',    // old Test Server-----
   baseUrl: 'http://45.33.89.37:5000/api/'    // Production Server
//

//baseUrl: 'http://45.79.142.60:5000/api/', //new staging 
    //baseUrl: 'http://52.25.96.244:6009/api/'
// baseUrl: 'https://localhost:44361/api/'
   // baseUrl: 'http://75.126.168.31:6124/api/'//smartdata
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
