import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phoneNumber'})
export class phoneNumberPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, args: any[] = []): string
    {
        if( value ) {
            return value.replace( /\D+/g, "" ).replace( /([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "($1) $2-$3" ); //mask numbers (xxx) xxx-xxxx	
        } else {
            return "";
        }
    }
}
