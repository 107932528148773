import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, finalize, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { COWTUXConfigService } from '@cowtux/services/config.service';
import { COWTUXSidebarService } from '@cowtux/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { UserProfile } from 'app/main/apps/shared/models/UserProfile';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CowtMasterService } from 'app/services/cowt-master.service';
import { Company, Branch } from 'app/main/apps/shared/models/Company';
import { COWTUXProgressBarService } from '@cowtux/components/progress-bar/progress-bar.service';
import { MasterDataService } from 'app/services/master-data.service';
import { cowtuxAnimations } from '@cowtux/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { UserProfilePhoto } from 'app/main/apps/shared/models/Login';
import { MatDialog } from '@angular/material';
import { GenericMessagePopupComponent } from 'app/main/apps/shared/generic-message/generic-message-popup/generic-message-popup.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations: cowtuxAnimations,
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    userStatusOptions: any;
    currentBranch: string;
    branches: Branch[];
    branchID: string;
    userData: any = {};
    legendData: { color: string; label: string; }[] = [];
    userProfilePhoto: UserProfilePhoto = new UserProfilePhoto();
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {COWTUXConfigService} _cowtuxConfigService
     * @param {COWTUXSidebarService} _cowtuxSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _cowtuxConfigService: COWTUXConfigService,
        private _cowtuxSidebarService: COWTUXSidebarService,
        private _translateService: TranslateService,
        private _router: Router,
        private _authService: AuthService,
        private _toastr: ToastrManager,
        private _cowtMasterService: CowtMasterService,
        private _cowtuxProgressBarService: COWTUXProgressBarService,
        private _masterDataService: MasterDataService,
        private _domSanitizer: DomSanitizer,
        private dialog: MatDialog,
    ) {
        console.log(this._router.url);
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.branchID = this._cowtMasterService.GetCurrentCompanyId();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/dispatchermodule/dispatcher')) {
          // Load legend data if the current route is for the Dispatcher component
          this.loadLegendData();
        }else {
            // Clear legend data if the current route is not for the Dispatcher component
            this.clearLegendData();
          }
      });
        // Subscribe to the config changes
        this._cowtuxConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
        this.onGetUserProfile();
        this.onGetUserBranchesSelectionOptions();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._cowtuxSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    onLogOut(): void {
        let defaultColumnSelection = localStorage.getItem('dispatcherSelectedColumns');
        localStorage.clear();
        localStorage.setItem('dispatcherSelectedColumns', defaultColumnSelection);
        this._router.navigate(['./login']);
    }
    onSelectedBranch(event): void {
        localStorage.removeItem('branchID');
        localStorage.setItem('branchID', event.branchID);
        this.currentBranch = event.branchName;

        this._router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

        let currentUrl = this._router.url + '?';

        this._router.navigateByUrl(currentUrl)
            .then(() => {
                this._router.navigated = false;
                this._router.navigate([this._router.url]);
            });
    }
    onGetUserBranchesSelectionOptions(): void {
        let allowedRoles = 'SuperAdmin';
        if (allowedRoles.includes(localStorage.getItem('roles')) && localStorage.getItem('roles') !== "") {

        }
        if (this.branchID) {
            this._cowtuxProgressBarService.show();
            this._masterDataService.GetUserBranchesSelectionOptions(this.branchID)
                .pipe(finalize(() => {
                    this._cowtuxProgressBarService.hide();
                }))
                .subscribe((x: Branch[]) => {
                    this.branches = x;
                    this.branches.map(d => {
                        const getBranchID = localStorage.getItem('branchID');
                        var branchID = +getBranchID;
                        if (d.branchID === branchID) {
                            this.currentBranch = d.branchName;
                        }
                    });
                })
        }

    }
    onGetUserProfile(): void {
        if (localStorage.getItem('auth_token')) {
            this._authService.GetUserProfile()
                .subscribe((x: UserProfile) => {
                    this._authService.UserProfilePhotoByID(x.id)
                        .pipe(finalize(() => { this._cowtuxProgressBarService.hide(); }))
                        .subscribe((profile: any) => {
                            if (profile === null) {
                                this.userProfilePhoto = new UserProfilePhoto();
                            } else {
                                this.userProfilePhoto = profile;
                                this.userProfilePhoto.userProfileImage = this.sanitize(this.userProfilePhoto.userProfileImage);
                            }
                        }, error => {
                            const dialogRef = this.dialog.open(GenericMessagePopupComponent,{
                                data:{
                                  message: error.error,
                                  buttonText: {
                                    cancel: 'Ok'
                                  }
                                },
                              });
                              console.log("toolbar.comp", this.userData)
                        });
                    // Reactive Form
                    this.userData.firstName = x.firstName;
                    this.userData.lastName = x.lastName;
                }, err => {
                    const dialogRef = this.dialog.open(GenericMessagePopupComponent,{
                        data:{
                          message: 'Your previous session has expired, please login!',
                          buttonText: {
                            cancel: 'Ok'
                          }
                        },
                      });
                      this.onLogOut();
                });
        }
    }
    sanitize(event) {
        return this._domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + event);
    }
    onChangeCompany() {
        this._router.navigate(['/apps/configmodule/change-company']);
    }

    loadLegendData(): void {
        // Load your legend data here
        // Example:
        this.legendData = [
          { color: '#ffb3b3', label: 'Cancelled' },
          { color: '#cdffa3', label: 'Added' },
          { color: '#fafab4', label: 'Unable To Cancel' }
        ];
      }
      clearLegendData(): void {
        // Clear the legend data
        this.legendData = [];
      }
}
