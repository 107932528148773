import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { COWTUXDirectivesModule } from '@cowtux/directives/directives';
import { COWTUXPipesModule } from '@cowtux/pipes/pipes.module';

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        COWTUXDirectivesModule,
        COWTUXPipesModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        COWTUXDirectivesModule,
        COWTUXPipesModule
    ]
})
export class COWTUXSharedModule
{
}
