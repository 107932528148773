import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-generic-save-confirmation-popup',
  templateUrl: './generic-save-confirmation-popup.component.html',
  styleUrls: ['./generic-save-confirmation-popup.component.scss']
})
export class GenericSaveConfirmationPopupComponent implements OnInit {
  title: string;
  message: string;
  RateValues:string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<GenericSaveConfirmationPopupComponent>) { 
    this.RateValues=data.result;
  }


  ngOnInit() {
  //  console.log(this.RateValues)
  document.getElementById("UserAddedData").innerHTML=(this.RateValues);

  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
