import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatInput } from '@angular/material';

@Component({
  selector: 'app-permile-bucket-to',
  templateUrl: './permile-bucket-to.component.html',
  styleUrls: ['./permile-bucket-to.component.scss']
})
export class PermileBucketToComponent implements OnInit {
  title: string;
  message: string;
  BucketToamount:number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<PermileBucketToComponent>) { 
  
  }


  ngOnInit() {
  //  console.log(this.data.result);
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.BucketToamount);
   
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  public get BucketTo() {
    if(this.BucketToamount==undefined||this.BucketToamount==null||this.BucketToamount==0)
      return true;
    return false;
  }
  

}
