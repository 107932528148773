import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { COWTUXNavigationComponent } from './navigation.component';
import { COWTUXNavVerticalItemComponent } from './vertical/item/item.component';
import { COWTUXNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { COWTUXNavVerticalGroupComponent } from './vertical/group/group.component';
import { COWTUXNavHorizontalItemComponent } from './horizontal/item/item.component';
import { COWTUXNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,

        TranslateModule.forChild()
    ],
    exports     : [
        COWTUXNavigationComponent
    ],
    declarations: [
        COWTUXNavigationComponent,
        COWTUXNavVerticalGroupComponent,
        COWTUXNavVerticalItemComponent,
        COWTUXNavVerticalCollapsableComponent,
        COWTUXNavHorizontalItemComponent,
        COWTUXNavHorizontalCollapsableComponent
    ]
})
export class COWTUXNavigationModule
{
}
