import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericMessagePopupComponent } from './generic-message-popup/generic-message-popup.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { GenericWarningPopupComponent } from './generic-warning-popup/generic-warning-popup.component';
import { GenericConfirmationPopupComponent } from './generic-confirmation-popup/generic-confirmation-popup.component';
import { GenericSaveConfirmationPopupComponent } from './generic-save-confirmation-popup/generic-save-confirmation-popup.component';
import { PermileBucketToComponent } from './permile-bucket-to/permile-bucket-to.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [GenericMessagePopupComponent, GenericWarningPopupComponent, GenericConfirmationPopupComponent, GenericSaveConfirmationPopupComponent, PermileBucketToComponent],
  imports: [
    CommonModule,// Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    FormsModule
  ],  
  entryComponents: [GenericMessagePopupComponent],
  providers: []
})
export class GenericMessageModule { }
