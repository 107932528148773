import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { COWTUXSearchBarModule, COWTUXShortcutsModule } from '@cowtux/components';
import { COWTUXSharedModule } from '@cowtux/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatTooltipModule } from '@angular/material';
import { GenericMessageModule } from 'app/main/apps/shared/generic-message/generic-message.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatTooltipModule,

        COWTUXSharedModule,
        COWTUXSearchBarModule,
        COWTUXShortcutsModule,
        GenericMessageModule
    ],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule {
}
